<template>
  <base-section id="theme-features">
    <base-section-heading title="Products and Services">
      AWM Research and Consulting and its team have been working on software
      development since 2013. We are eager to work with you to design and
      develop tailormade solutions that will help your business to scale.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="feature" align="left" horizontal>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            illum veniam cum dolores ratione commodi beatae quas maxime,
            laboriosam excepturi solut!
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        title: "SMS Messaging",
        icon: "mdi-fountain-pen-tip",
        text: "AWM, through its Bulk SMS platform, offers a valid solution to Enterprises to communicate via SMS with their customers.",
      },
      {
        title: "A2P Wholesale Platform",
        icon: "mdi-cellphone",
        text: "A cloud-based white label business platform for any company that wants to be an A2P Messaging Wholesaler.",
      },
      {
        color: "primary",
        dark: true,
        title: "m-Marketing Platform",
        icon: "mdi-pencil-box-outline",
        text: "A cloud-based white label business platform for any company that wants to be a Mobile Marketing services provider.",
      },
      {
        title: "Dashboards",
        icon: "mdi-monitor-dashboard",
        text: "The 1st Business Intelligence tool, designed and developed specifically for the mobile messaging, marketing & payments market. It is offered as an add-on to all our platforms.",
      },
      {
        title: "Mobile Coupons",
        icon: "mdi-ice-pop",
        text: "AWM offers to companies the capability to run mobile coupon campaigns, in order to enrich the coupon experience of users and build the Loyalty of its customers.",
      },
      {
        title: "24/7 Support",
        icon: "mdi-help-circle-outline",
        text: "When it gets mission critical, we are there to make sure everything runs smoothly. Choose the support level that fits your needs and we will be there to support your business.",
      },
    ],
  }),
};
</script>
